import authCardBg from './images/auth-card-left-col.jpg'
import authMobileBg from './images/auth-mobile-bg.png'
import loadingDesktopBg from './images/loading-desktop-bg.jpg'
import loadingMobileBg from './images/loading-mobile-bg.jpg'
import landingMainBg from './images/landing-main-bg.jpg'
import contentCardRightSide from './images/content-card-right-side.jpg'

import { ProductName } from '../../types/interface/quote.interface'
import {
  ThemeBrandAssetsType,
  ThemeBrandColorsType,
  ThemeBrandTypographyType,
} from 'BrandTheme/types'
import brandTheme from '../theme/utils'
import logoMin from './images/logoMin.png'

const initCallback = () => {
  const productName: ProductName = ProductName.VEHICLE
  const doneDesktopBg = authCardBg
  const doneMobileBg = authMobileBg
  const errorDesctopBg = authCardBg
  const collisionMobileBackground = authMobileBg

  const brandColors: ThemeBrandColorsType = {
    main: '#78FAAE',
    secondary: '#0E3A2F',
    background: '#F3F3F3',
    headingMain: '#000',
    headingSecondary: '#000',
    textMain: '#000',
    textSecondary: '#768692',
    textError: '#F84971',
    textPlaceholder: '#C8CFD3',
    textDisclaimer: '#B6BABC',
    textInactive: '#C8CFD3',
    buttonActive: '#0E3A2F',
    buttonInactive: '#d5d5d5',
    inputActive: '#4cad77',
    inputInactive: '#678f79',
    linkMain: '#000',
    linkSecondary: '#419468',
    modal: '#C4C4C4',
    shadow: '#000000',
    clean: '#FFFFFF',
    cardHeader: '#0E3A2F',
    textCardHeader: '#78FAAE',
    textCardFooter: '#0E3A2F',
  }

  const brandTypography: ThemeBrandTypographyType = {
    heading: {
      h1: {
        fontSize: '1.75rem', // 28px
        fontWeight: 500,
        color: brandColors.headingMain,
        fontFamily: 'Roboto',
        lineHeight: 1.4,
      },
      h2: {
        fontSize: '1.5rem', // 24px
        fontWeight: 500,
        color: brandColors.headingMain,
        fontFamily: 'Roboto',
        lineHeight: 1.4,
      },
      h3: {
        fontSize: '1.25rem', // 20px
        fontWeight: 400,
        color: brandColors.headingSecondary,
        fontFamily: 'Roboto',
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '1rem', // 16px
        fontWeight: 500,
        color: brandColors.headingMain,
        fontFamily: 'Roboto',
        lineHeight: 1.4,
      },
      h5: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
        color: brandColors.textMain,
        fontFamily: 'Roboto',
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
        color: brandColors.headingMain,
        fontFamily: 'Roboto',
        lineHeight: 1.4,
      },
    },
    text: {
      xSmall: {
        fontSize: '0.688rem', // 11px
        lineHeight: 1.4,
        fontFamily: 'Roboto',
      },
      small: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
      },
      normal: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4,
      },
      large: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.4,
      },
      xLarge: {
        fontSize: '2.5rem', // 40px
        lineHeight: 1.4,
      },
    },
    modifiers: {
      bold: {
        fontWeight: 700,
      },
      decorated: {
        textDecoration: 'underline',
      },
      unDecorated: {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'none',
        },
      },
      withHover: {
        '&:hover': {
          opacity: 0.75,
          cursor: 'pointer',
        },
      },
      preFormatted: {
        whiteSpace: 'pre',
      },
    },
    overrides: {
      ownerRadioOverride: {
        fontWeight: 700,
      },
      iconColor: {
        color: brandColors.buttonActive,
      },
      bgBreadcrumbsColorOverride: {
        backgroundColor: brandColors.secondary,
      },
      contactCustomerSwitchText: {
        color: brandColors.textSecondary,
      },
      reviewRightColItem: {
        color: brandColors.shadow,
      },
      landingTitle: {
        color: `${brandColors.main} !important`,
      },
    },
    extraFonts: [],
  }

  const theme = brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
  }).theme

  const productBackgroundsMap = {
    [ProductName.VEHICLE]: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 20%), 
      url(${loadingMobileBg}) no-repeat center bottom`,
      loading: `url(${loadingDesktopBg}) no-repeat center center fixed`,
      authDesktop: brandColors.background,
      authMobileBg: `url(${authMobileBg}) no-repeat left top scroll`,
      reviewBg: '',
    },
  }

  const loadingBackground = productBackgroundsMap[productName].loading
  const loadingMobileBackground = productBackgroundsMap[productName].loadingMobile

  const mainBackgroung = productBackgroundsMap[productName].authDesktop
  const mainBackgroungMobile = productBackgroundsMap[productName].authMobileBg

  const reviewBackground = productBackgroundsMap[productName].reviewBg

  const overrides = {
    contained: {
      '&:hover': {
        color: brandColors.clean,
        opacity: 0.75,
        '&.MuiSvgIcon-root': {
          color: brandColors.clean,
        },
      },
      '&:disabled': {
        backgroundColor: brandColors.buttonInactive,
        color: brandColors.textInactive,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: brandColors.textMain,
        '&.Mui-selected': {
          color: brandColors.secondary,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: brandColors.linkSecondary,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: brandColors.textMain,
      },
    },
    MuiSwitch: {
      root: {
        width: 74,
        height: 48,
        overflow: 'visible',
        '& .MuiSwitch-track': {
          width: 70,
          borderRadius: 12,
        },
        '& .MuiSwitch-input': {
          width: '200%',
        },
        '& .MuiSwitch-thumb': {
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-switchBase': {
          top: 6,
          left: 6,
          '& .MuiIconButton-label': {
            color: brandColors.clean,
          },
          '&.Mui-checked': {
            transform: 'translateX(30px)',
            left: 2,
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: brandColors.clean,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: brandColors.linkSecondary,
              opacity: 1,
            },
          },
        },
      },
    },
    MuiCardActionArea: {
      root: {
        '& .MuiTouchRipple-root': {
          color: brandColors.main,
        },
      },
      focusHighlight: {
        backgroundColor: brandColors.main,
      },
    },
    MuiIconButton: {
      label: {
        color: brandColors.secondary,
      },
      root: {
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            color: brandColors.buttonInactive,
          },
        },
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        '& .MuiIconButton-label': {
          color: brandColors.buttonInactive,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 28,
        color: brandColors.shadow,
        height: 48,
        fontWeight: 700,
      },
      outlined: {
        '&:hover': {
          backgroundColor: brandColors.secondary,
          color: brandColors.clean,
        },
        '&:active': {
          backgroundColor: brandColors.secondary,
          color: brandColors.clean,
        },
        '@media (hover:none)': {
          '&:hover': {
            color: brandColors.main,
            backgroundColor: brandColors.clean,
            opacity: '1 !important',
          },
          '&:active': {
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
        },
      },
      contained: {
        boxShadow: 'none',
        fontWeight: 700,
        '&:hover': {
          color: brandColors.shadow,
          backgroundColor: `${brandColors.main} !important`,
          border: brandColors.textMain,
          opacity: 0.7,
          boxShadow: 'none',
          '& .MuiSvgIcon-root': {
            color: brandColors.shadow,
          },
          '&#change-offer-button': {
            backgroundColor: `${brandColors.background} !important`,
            border: `1px solid ${brandColors.shadow} !important`,
          },
        },
        '@media (hover:none)': {
          '&:hover': {
            opacity: '1 !important',
          },
        },
        '&:disabled': {
          backgroundColor: brandColors.buttonInactive,
          color: brandColors.clean,
        },
        '&#ctaBtnNext:hover': {
          backgroundColor: `${brandColors.secondary} !important`,
          color: brandColors.clean,
        },
        '&#ctaBtnNext:active': {
          color: brandColors.clean,
          backgroundColor: `${brandColors.secondary} !important`,
        },
      },
    },
  }

  const brandAssets: ThemeBrandAssetsType = {
    pageBackgrounds: {
      main: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      mainMobile: {
        background: mainBackgroungMobile,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      mainCard: {
        background: `url(${authCardBg}) no-repeat scroll`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
      },
      secondary: {
        background: brandColors.clean,
        backgroundSize: 'cover',
      },
      secondaryMobile: {
        background: `url(${doneMobileBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryCard: {
        background: `url(${doneDesktopBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      error: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      errorCardMain: {
        background: `url(${errorDesctopBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardMobileMain: {
        background: `url(${collisionMobileBackground}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      loading: {
        alignContent: 'center',
        background: loadingBackground,
        backgroundSize: 'cover',
      },
      loadingMobile: {
        background: loadingMobileBackground,
        backgroundSize: 'cover',
      },
      review: {
        background: reviewBackground,
        backgroundSize: 'contain',
      },
      verify: {
        background: reviewBackground,
        backgroundSize: 'contain',
      },
    },
    images: {
      changeVehicleOwnerPageCard: {
        background: `url(${contentCardRightSide}) no-repeat right top scroll`,
        backgroundSize: '50%, auto, cover',
        backgroundPosition: 'right',
      },
      logoDesktopHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: 320,
        minWidth: 320,
        display: 'flex',
        flex: 1,
      },
      logoImageRightCol: {
        backgroundSize: 'auto, cover',
        display: 'flex',
        flex: 1,
        maxWidth: '100%',
        height: '32px',
        width: '197px',
      },
      logoMobileHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: 'contain',
        height: 60,
      },
      logoMax: {
        width: 'auto',
        height: 'auto',
        margin: '60px 0px',
        maxWidth: '100%',
        alignSelf: 'baseline',
        position: 'relative',
        left: '30px',
        maxHeight: '109px',
      },
      landingMainBg: {
        background: `url(${landingMainBg}) no-repeat right center scroll`,
      },
    },
    layout: {
      logoWrapper: {
        justifyContent: 'end',
      },
      mobileButton: {
        borderRadius: 28,
        '&:hover': {
          opacity: '.75',
          background: brandColors.main,
        },
      },
      customFnolButtonStyle: {
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3) !important`,
        borderRadius: 8,
        color: brandColors.buttonActive,
        fontWeight: 500,
        '&:hover': {
          color: `${brandColors.buttonActive} !important`,
          fontWeight: 700,
        },
      },
      card: {
        cursor: 'pointer',
        '&:hover p, &:visited p, &:active p': {
          color: brandColors.headingMain,
        },
        '&:hover, &:visited': {
          backgroundColor: brandColors.main,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          opacity: 0.7,
        },
      },
      mobileCustomLogoWrapper: {
        position: 'relative',
        top: '-60px',
      },
      mobileCustomTitle: {
        fontSize: '20px',
      },
      mobileCustomHeadingText: {
        marginTop: 'auto',
        fontWeight: 400,
        color: brandColors.headingMain,
      },
      mobileCustomSubTitle: {
        marginTop: 'auto',
        fontSize: '12px',
        color: brandColors.headingMain,
      },
      LMBgHeaderHeader: {
        [theme.breakpoints.down('sm')]: {
          backgroundPositionY: '-40px',
          height: 300,
          marginTop: '65px',
        },
      },
      pageBackgroundSm: {
        height: 270,
      },
      loadingTitle: {
        color: `${brandColors.clean} !important`,
      },
      loadingTitleMobile: {
        marginTop: '14px',
        color: `${brandColors.headingMain} !important`,
      },
      customLabelRadio: {
        overflow: 'hidden',
        '&:nth-child(1)': {
          borderRadius: '50px 0px 0px 50px',
        },
        '&:nth-child(2)': {
          borderRadius: '0px 50px 50px 0px',
        },
      },
      customInputRadio: {
        backgroundColor: 'none',
        '&:hover ': {
          color: brandColors.clean,
        },
      },
      btnNext: {
        border: `1px solid ${brandColors.shadow}`,
        backgroundColor: brandColors.secondary,
        color: brandColors.clean,
        '&.MuiSvgIcon-root': {
          backgroundColor: 'transparent',
          color: brandColors.shadow,
        },
        '&#change-offer-button': {
          backgroundColor: brandColors.clean,
          color: brandColors.shadow,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          '&:hover': {
            backgroundColor: brandColors.secondary,
            color: brandColors.clean,
            '& svg': {
              fill: brandColors.clean,
            },
          },
          '@media (hover:none)': {
            '&:hover': {
              backgroundColor: brandColors.clean,
              color: brandColors.shadow,
            },
            '&:hover svg': {
              fill: brandColors.shadow + ' !important',
            },
            '&:active': {
              backgroundColor: brandColors.secondary,
              color: brandColors.clean,
              '& svg': {
                fill: brandColors.clean + ' !important',
              },
            },
          },
        },
      },
      customHeaderTextWrapper: {
        background: brandColors.clean,
        padding: '16px',
        zIndex: 10,
        marginTop: '-100px',
      },
      ctaOverride: {
        color: brandColors.shadow,
      },
      ctaAnnotation: {
        color: brandColors.linkSecondary,
      },
      nextBtn: {
        background: brandColors.secondary,
        color: brandColors.clean,
        border: `1px solid ${brandColors.shadow}`,
        '&:hover': {
          color: brandColors.clean,
          backgroundColor: `${brandColors.buttonActive} !important`,
          border: brandColors.textMain,
          opacity: 0.7,
        },
      },
      loadingBarOverwrite: {
        backgroundColor: brandColors.linkSecondary,
      },
      checkedLabel: {
        color: brandColors.shadow,
      },
      iconCustomColor: {
        color: brandColors.secondary,
      },
      contacts: {
        color: brandColors.secondary,
      },
      buttonLink: {
        background: 'none',
        maxWidth: 'fit-content',
        boxShadow: 'none',
        color: brandColors.shadow,
        textTransform: 'capitalize',
        fontSize: 12,
        padding: 0,
        height: 'auto',
        textDecoration: 'underline',
        marginBottom: 4,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: 'transparent !important',
          color: brandColors.shadow,
          boxShadow: 'none',
          textDecoration: 'underline',
        },
      },
      customMobileSubmit: {
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          bottom: 0,
        },
      },
    },
  }
  return brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
    assets: brandAssets,
    overrides,
  })
}

export default brandTheme.init(initCallback)
