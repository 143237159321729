import { FSMConfigVehicleNO, FSMOptionsVehicleNO } from './qb/vehicle/config'
import { FSMVehicleStatePathsNO } from './qb/vehicle/routes'

import { FSMInputType } from '../../../types/FSM'
import { FlowName } from '../../../config'
import { ProductName } from '../../../types/interface/quote.interface'

import { FSMConfigMyPagesNO, FSMOptionsMyPagesNO } from './mypages/vehicle/config'
import { FSMMyPagesStatePathsNO } from './mypages/vehicle/routes'

// TODO: Fix types for the `options`
const BrandFSMInput: Partial<FSMInputType> = {
  [FlowName.QUOTE_AND_BUY]: {
    [ProductName.VEHICLE]: {
      config: FSMConfigVehicleNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsVehicleNO,
      routes: FSMVehicleStatePathsNO,
    },
  },

  [FlowName.MY_PAGES]: {
    [ProductName.VEHICLE]: {
      config: FSMConfigMyPagesNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsMyPagesNO,
      routes: FSMMyPagesStatePathsNO,
    },
  },
}

export default BrandFSMInput
