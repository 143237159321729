import { landingCar } from 'images'
import React, { FC } from 'react'
import { getDataComponentName } from '@dg-util'

const LandingAboutImage: FC<{ alt: string; className: string }> = ({ className, alt }) => (
  <img
    data-componentname={getDataComponentName(__filename)}
    src={landingCar}
    alt={alt}
    className={className}
  />
)

export default LandingAboutImage
